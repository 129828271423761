import './chat-panel.scss';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MdMinimize, MdOutlineClose } from 'react-icons/md';

import { useUser } from '@/features/users/context/userContext';
import { Tooltip } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useAppTracking } from 'hooks/useTracking';
import { useWebSocket } from 'hooks/useWebsocket';
import { labels } from 'locales/en.label';
import React from 'react';
import { IoArrowUpCircleSharp } from 'react-icons/io5';
import { TrackingEnum } from 'types/TrackingTypes';
import { v4 as uuid } from 'uuid';

interface ChatPanelProps {
  toggleChat: React.Dispatch<React.SetStateAction<boolean>>;
}

enum MessageType {
  TEXT = 'text',
  END = 'end',
  BLOCKED = 'blocked',
}

enum Role {
  USER = 'user',
  ASSISTANT = 'assistant',
}

interface Message {
  id: string;
  role: Role;
  type?: 'text' | 'end' | 'blocked';
  content: string;
  blockedId?: string;
  usage?: {
    promptTokens: number;
    completionTokens: number;
    totalTokens: number;
  };
}

const TypingIndicator = () => {
  return (
    <div className='typing-indicator'>
      <div className='typing-dot'></div>
      <div className='typing-dot'></div>
      <div className='typing-dot'></div>
    </div>
  );
};

function ChatPanel({ toggleChat }: ChatPanelProps) {
  const { user } = useUser();
  const [chatMinimized, setChatMinimized] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [message, setMessage] = useState('');
  const [streamingMessage, setStreamingMessage] = useState<Message | null>(
    null
  );
  const { track } = useAppTracking();

  const sessionId = uuid();

  const { authState } = useOktaAuth();
  const token = authState?.accessToken?.accessToken;
  const wsUrl = useMemo(() => {
    return `${process.env
      .REACT_APP_BEDROCK_WS_API_URL!}?token=${encodeURIComponent(token!)}`;
  }, [token]);

  const handleMessage = useCallback(
    (data: Message) => {
      setWaiting(false);
      switch (data.type) {
        case MessageType.TEXT:
          setStreamingMessage({
            ...data,
            content: data.content.trim(),
          });
          break;
        case MessageType.END:
          setMessages((prevMessages) => [...prevMessages, data]);
          setStreamingMessage(null);
          track(
            {
              action: 'ChatPanel-Message',
              component: 'chat-panel',
              value: JSON.stringify({
                chatMessageId: data.id,
                conversationOld: messages,
                conversationNew: [
                  ...messages,
                  {
                    id: data.id,
                    role: Role.ASSISTANT,
                    content: data.content,
                  },
                ],
                usage: data.usage,
              }),
            },
            TrackingEnum.DATA_LAKE
          );
          break;
        case MessageType.BLOCKED:
          setMessages((prevMessages) => [
            ...prevMessages.map((msg: Message) => {
              if (msg.id === data.blockedId) {
                return {
                  ...msg,
                  content: 'Message Blocked',
                };
              }
              return msg;
            }),
            data,
          ]);
          setStreamingMessage(null);
          break;
        default:
          break;
      }
    },
    [messages, track]
  );

  const setWaitingFalse = useCallback(() => {
    setWaiting(false);
  }, []);

  const { websocket } = useWebSocket<Message>({
    url: wsUrl,
    onMessage: handleMessage,
    onOpen: setWaitingFalse,
    onClose: setWaitingFalse,
  });

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatMessagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, streamingMessage]);

  useEffect(() => {
    if (!chatMinimized && chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [chatMinimized]);

  const sendMessage = (event: React.FormEvent) => {
    event.preventDefault();
    setWaiting(true);
    const newMessage = {
      id: uuid(),
      role: Role.USER,
      content: message,
    };
    setMessages((prev) => [...prev, newMessage]);
    if (websocket && message.trim()) {
      websocket.send(
        JSON.stringify({
          action: 'startStream',
          messages: [...messages, newMessage],
          sessionId,
          token,
          userId: user?.id ?? 'unknown-user',
        })
      );
      setMessage('');
    }
  };

  const handleMinimize = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setChatMinimized(true);
  };

  const handleHeaderClick = () => {
    if (chatMinimized) {
      setChatMinimized(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage(e);
    }
  };

  const formatText = (text: string) => {
    if (!text) return null;
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className={`chat-window ${!chatMinimized && 'chat-full'}`}>
      <header
        className={`${chatMinimized ? 'header-button' : ''}`}
        onClick={handleHeaderClick}
      >
        {labels.chat.title}
        <div className='window-controls'>
          {!chatMinimized && (
            <Tooltip
              title={labels.chat.minimize}
              placement='top'
              arrow
              enterDelay={1500}
            >
              <button onClick={handleMinimize}>
                <MdMinimize className='control-icon' />
              </button>
            </Tooltip>
          )}
          <Tooltip
            title={labels.chat.close}
            placement='top'
            arrow
            enterDelay={1500}
          >
            <button onClick={() => toggleChat(false)}>
              <MdOutlineClose className='control-icon close' />
            </button>
          </Tooltip>
        </div>
      </header>
      {!chatMinimized && (
        <>
          <div className='chat-messages' ref={chatMessagesRef}>
            {messages.map((message, index) => (
              <div
                key={index}
                className={`message-wrapper wrapper-${message.role}`}
              >
                <div className={`message ${message.role}-msg`}>
                  {formatText(message.content)}
                </div>
              </div>
            ))}
            {streamingMessage && (
              <div className='message-wrapper wrapper-assistant'>
                <div className='message assistant-msg'>
                  {formatText(streamingMessage.content)}
                </div>
              </div>
            )}
            {waiting && <TypingIndicator />}
            <div ref={messagesEndRef} />
            {!websocket && <div className='connecting-msg'>Connecting...</div>}
          </div>
          <form onSubmit={sendMessage} className='chat-input'>
            <textarea
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus
              placeholder={labels.chat.placeholder}
              value={message}
            ></textarea>
            <Tooltip
              title={labels.chat.send}
              placement='top'
              arrow
              enterDelay={1500}
            >
              <button
                type='submit'
                disabled={waiting || !message.trim().length || !websocket}
              >
                <IoArrowUpCircleSharp className='send-button' />
              </button>
            </Tooltip>
          </form>
        </>
      )}
    </div>
  );
}

export default ChatPanel;
