import {
  calculateCompRoomCost,
  calculateCostofGuestsWalked,
  calculateDisplacementCost,
  calculateFBMeetingBenefit,
  calculateForecastADR,
  calculateGroupRatio,
  calculateGuestsWalked,
  calculateIncrementalCommission,
  calculateIncrementalRoomRevenue,
  calculateNetBenefit,
  calculateNetRoomsAdded,
  calculateOverride,
  calculatePlannersAdvCost,
  calculateRebateCost,
  calculateRequiredProfit,
  calculateRoomsDisplaced,
  calculateShoulderDisplacementCost,
  calculateShoulderRoomsDisplaced,
  calculateSuggestedRate,
  calculateTakeGroup,
  calculateVariableOpEx,
} from 'features/group-pricing/group-pricing-utils';
import {
  formattedCurrency,
  twoDecimalRateFormatter,
} from 'pages/MyView/tableCustomCellRender';
import { useEffect, useRef } from 'react';
import {
  useGetGroupProbabilityQuery,
  useGetWinPercentagesQuery,
} from 'features/group-pricing/gql/_gen_/group-pricing.gql';

import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { FaCheck } from 'react-icons/fa';
import { GroupPriceToolInput } from './group-price-tool';
import { MdClose } from 'react-icons/md';
import { NetBenefitData } from './group-price-tool';
import { SuggestedRate } from './suggested-rate';
import { Tooltip } from '@mui/material';
import type { UseFormSetValue } from 'react-hook-form';
import { formatDate } from 'features/dates/date-helpers';
import { sortBy } from 'lodash';

const defaultColOptions: ColDef = {
  width: 100,
  resizable: true,
  suppressSizeToFit: true,
};

const FieldColumnCell = (props: any) => {
  return (
    <Tooltip title={props.data.description} arrow placement='right'>
      <div>{props.value}</div>
    </Tooltip>
  );
};

type TransformedData = {
  field: string;
  [stayDate: string]: number | string;
};

interface NetBenefitTableProps {
  data: NetBenefitData[];
  dates: string[];
  formValues: GroupPriceToolInput;
  setValue: UseFormSetValue<GroupPriceToolInput>;
}

export const NetBenefitTable = ({
  data,
  dates,
  formValues,
  setValue,
}: NetBenefitTableProps) => {
  const gridRef: React.RefObject<AgGridReact> = useRef(null);

  const floorRatio = 0.8;
  const optimalRatio = 0.91;
  const ceilingRatio = 1;

  useEffect(() => {
    if (gridRef.current) {
      gridRef?.current?.api?.setDomLayout('autoHeight');
    }
  }, []);

  const fieldMapping: { [key: string]: string } = {
    total_capacity: 'Capacity',
    current_otb: 'On the Books',
    forecast_transient_adr: 'Forecast Transient ADR',
    total_fcst: 'Transient Forecast',
    transient_and_group_fcst: 'Transient and Group Forecast',
    new_group_rooms_requested: 'New Group Rooms Requested',
    net_rooms_added: 'Net Rooms Added',
    rooms_displaced: 'Rooms Displaced',
    guests_walked: 'Guests Walked',
    shoulder_rooms_displaced: 'Shoulder Rooms Displaced',
    incremental_room_revenue: 'Incremental Room Revenue',
    cost_of_guests_walked: 'Cost of Guests Walked',
    incremental_direct_variable_opex: 'Incremental Direct Variable OPEX',
    incremental_commission: 'Incremental Commission',
    comp_room_cost: 'Comp Room Cost',
    rebate_cost: 'Rebate Cost',
    displacement_cost: 'Displacement Cost',
    shoulder_displacement_cost: 'Shoulder Displacement Cost',
    combined_f_and_b_meeting_space_benefit:
      'Combined F&B, Meeting Space Benefit',
    planners_advantage_points_cost: 'Planners Advantage Points Cost',
    net_benefit: 'Net Benefit',
    required_profit: 'Required Profit',
  };

  const fieldDescriptions: { [key: string]: string } = {
    total_capacity:
      'This is the total number of rooms you have in your hotel, less any rooms that are out of order. In other words, it is the total number of rooms you have available to sell, which may vary by date.',
    current_otb:
      'This is the number of rooms that you currently have booked for each night.',
    forecast_transient_adr:
      'The expected transient ADR forecast for the selected stay date, based upon historical known data for that same stay date for previous years and recent ADR trends.',
    transient_fcst:
      "This is the total number of non-Group rooms you should expect to sell. The transient forecast is based on how many transient guests you've had in house over the equivalent dates in previous years, as well as how the current number of transient guests on the books compares to the equivalent point in time in previous years",
    transient_and_group_fcst:
      'This is the forecast of how many transient rooms you should expect, with the addition of any group you have already on the books.',
    new_group_rooms_requested:
      'This is the number you enter at the top of the page. I.E. the total number of rooms requested by the new group.',
    net_rooms_added:
      "Whenever you are expected to have enough rooms available to accommodate the new Group, the 'net rooms added' will equal the number of rooms requested minus washed rooms. But if taking the group will cause you to displace a transient guest, the net rooms added will equal the number of Group rooms requested - washed rooms - the number of transient rooms displaced.",
    rooms_displaced: 'Number of requested rooms minus net rooms added.',
    guests_walked:
      'When the new group rooms requested exceeds the capacity available today and transient room nights on the books currently exists.',
    shoulder_rooms_displaced:
      'If rooms are displaced on the first or last night, the shoulder rooms displaced is calculated by multiplying the displaced rooms by the average length of stay from the form.',
    incremental_room_revenue:
      'Net rooms added minus wash percentage multiplied by the group rate request as entered in the controls above. The model assumes that in the absence of the group, none of the would-be group guests would book your hotel.',
    cost_of_guests_walked:
      'The number of guests walked multiplied by the difference between the transient rate and the group rate.',
    incremental_direct_variable_opex:
      'The incremental direct variable operating expenses associated with the new group. This is calculated as the net rooms added multiplied by the direct variable operating expenses per room night.',
    displacement_cost:
      'In the event the group is expected to displace transient rooms, this value is calculated as the number of displaced transient guests multiplied by the differential between the group rate and the transient rate.',
    shoulder_displacement_cost:
      'In the event shoulder rooms are displaced, this calculation is the average length of stay multiplied by the number of rooms displaced multiplied by the difference between the transient rate and the group rate.',
    combined_f_and_b_meeting_space_benefit:
      'The value a user input in the control “F&B profit per group room night”. This is the incremental net benefit you would expect to receive, expressed on a per room night basis.',
    planners_advantage_points_cost:
      'The cost of points issued to a meeting planner through the Planners Advantage Program. 1 Point is equal to $0.0055 USD.',
    net_benefit:
      'The sum of incremental revenue, expected meeting space and F&B benefit, minus the cost of displacement (including shoulder displacement), incremental direct variable costs, commission, rebates and comp rooms.',
    required_profit:
      'Calculated as the number of rooms requested multiplied by the rate requested, multiplied by the profit margin percentage from the form.',
    take_group:
      'This field will display as a green check mark if the net benefit exceeds the required profit. If not, the field will display as a red X if the net benefit does not exceed the required profit.',
  };

  const groupRatio = calculateGroupRatio(
    data,
    formValues.rate_requested,
    formValues.rooms_requested
  );

  const { data: probabilityData } = useGetGroupProbabilityQuery({
    variables: {
      ratio: groupRatio,
    },
  });

  const { data: winPercentageData } = useGetWinPercentagesQuery({
    variables: {
      floorRatio,
      optimalRatio,
      ceilingRatio,
    },
  });

  const currentWinProbability =
    probabilityData?.getGroupProbability?.winning_probability;

  const optimalGroupRate = calculateSuggestedRate(
    data,
    formValues.rooms_requested,
    optimalRatio
  );

  const floorGroupRate = calculateSuggestedRate(
    data,
    formValues.rooms_requested,
    floorRatio
  );

  const ceilingGroupRate = calculateSuggestedRate(
    data,
    formValues.rooms_requested,
    ceilingRatio
  );

  const optimalWinProbability = winPercentageData?.getWinPercentages?.optimal;
  const floorWinProbability = winPercentageData?.getWinPercentages?.floor;
  const ceilingWinProbability = winPercentageData?.getWinPercentages?.ceiling;

  const calculateData = (data: NetBenefitData[]) => {
    const calculatedData = data.map((dataEntry) => {
      const {
        rooms_requested,
        comp_rooms,
        rate_requested,
        rebate,
        planners_adv_points,
        direct_var_opex,
        occ_fcst_override,
        adr_fcst_override,
        wash_factor,
        commission,
        profit_margin,
        f_and_b_profit,
        avg_stay_length,
      } = formValues;
      const {
        current_otb,
        total_capacity,
        total_fcst,
        group_booked,
        fcst_rev,
        stay_date,
      } = dataEntry;

      const forecast_transient_adr = calculateForecastADR(
        fcst_rev,
        total_fcst,
        adr_fcst_override
      );

      const new_group_rooms_requested =
        typeof rooms_requested === 'string'
          ? parseInt(rooms_requested)
          : rooms_requested;

      const total_fcst_override =
        occ_fcst_override !== 0
          ? calculateOverride(total_fcst, occ_fcst_override)
          : total_fcst;

      const transient_and_group_fcst = total_fcst_override + group_booked;

      const rooms_displaced = calculateRoomsDisplaced(
        total_capacity,
        new_group_rooms_requested,
        transient_and_group_fcst
      );

      const net_rooms_added = calculateNetRoomsAdded(
        total_capacity,
        new_group_rooms_requested,
        transient_and_group_fcst,
        wash_factor
      );

      const guests_walked = calculateGuestsWalked(
        total_capacity,
        new_group_rooms_requested,
        current_otb
      );

      const shoulder_rooms_displaced = calculateShoulderRoomsDisplaced(
        rooms_displaced,
        avg_stay_length,
        dates[0],
        dates[dates.length - 1],
        stay_date
      );

      const shoulder_displacement_cost = calculateShoulderDisplacementCost(
        shoulder_rooms_displaced,
        forecast_transient_adr,
        rate_requested
      );

      const incremental_room_revenue = calculateIncrementalRoomRevenue(
        net_rooms_added,
        rate_requested
      );

      const cost_of_guests_walked = calculateCostofGuestsWalked(
        guests_walked,
        forecast_transient_adr
      );

      const incremental_direct_variable_opex = calculateVariableOpEx(
        net_rooms_added,
        direct_var_opex
      );

      const incremental_commission = calculateIncrementalCommission(
        rooms_displaced,
        forecast_transient_adr,
        commission,
        rate_requested
      );

      const comp_room_cost = calculateCompRoomCost(comp_rooms, rate_requested);

      const rebate_cost = calculateRebateCost(rebate, rooms_requested);

      const displacement_cost = calculateDisplacementCost(
        rooms_displaced,
        forecast_transient_adr,
        rate_requested
      );

      const combined_f_and_b_meeting_space_benefit = calculateFBMeetingBenefit(
        f_and_b_profit,
        net_rooms_added
      );

      const planners_advantage_points_cost = calculatePlannersAdvCost(
        planners_adv_points,
        rate_requested,
        rooms_requested
      );

      const net_benefit = calculateNetBenefit(
        incremental_room_revenue,
        cost_of_guests_walked,
        comp_room_cost,
        rebate_cost,
        displacement_cost,
        incremental_direct_variable_opex,
        incremental_commission,
        combined_f_and_b_meeting_space_benefit,
        planners_advantage_points_cost
      );

      const required_profit = calculateRequiredProfit(
        rooms_requested,
        rate_requested,
        profit_margin
      );

      return {
        ...dataEntry,
        forecast_transient_adr,
        total_fcst: total_fcst_override,
        new_group_rooms_requested,
        net_rooms_added,
        rooms_displaced,
        guests_walked,
        shoulder_rooms_displaced,
        transient_and_group_fcst,
        incremental_room_revenue,
        cost_of_guests_walked,
        incremental_direct_variable_opex,
        incremental_commission,
        comp_room_cost,
        rebate_cost,
        displacement_cost,
        shoulder_displacement_cost,
        combined_f_and_b_meeting_space_benefit,
        planners_advantage_points_cost,
        net_benefit,
        required_profit,
      };
    });
    return calculatedData;
  };

  const take_group = calculateTakeGroup(data, formValues);

  const transformData = (): TransformedData[] => {
    const calculatedData = calculateData(data);
    const fields = Object.keys(fieldMapping);

    const transformedData: TransformedData[] = fields.map((field) => {
      const row: TransformedData = { field: fieldMapping[field] };
      row.description = fieldDescriptions[field];

      calculatedData.forEach((dataEntry) => {
        if (field === 'forecast_transient_adr') {
          row[dataEntry.stay_date] = Number(
            dataEntry[field as keyof NetBenefitData]
          ).toFixed(2);
          return;
        }
        row[dataEntry.stay_date] = dataEntry[field as keyof NetBenefitData];
      });

      return row;
    });

    return transformedData;
  };

  const transformedData = transformData();

  const totalValueFormatter = (params: any) => {
    if (params.data.field === 'Forecast Transient ADR') {
      const sum = dates.reduce((acc, date) => {
        const totalForDate = parseFloat(params.data[date]);
        return acc + (typeof totalForDate === 'number' ? totalForDate : 0);
      }, 0);

      return (sum / dates.length).toFixed(2);
    }

    const total = dates.reduce((acc, date) => {
      const totalForDate = params.data[date];
      return acc + (typeof totalForDate === 'number' ? totalForDate : 0);
    }, 0);

    return total;
  };

  const currencyFields = [
    'Forecast Transient ADR',
    'Incremental Room Revenue',
    'Cost of Guests Walked',
    'Incremental Direct Variable OPEX',
    'Incremental Commission',
    'Comp Room Cost',
    'Rebate Cost',
    'Displacement Cost',
    'Shoulder Displacement',
    'Combined F&B, Meeting Space Benefit',
    'Planners Advantage Points Cost',
    'Net Benefit',
    'Required Profit',
  ];

  const columnDefs: ColDef[] = [
    {
      headerName: 'Detailed Net Benefit Estimate',
      field: 'field',
      pinned: 'left',
      width: 250,
      cellRenderer: FieldColumnCell,
    },
    {
      headerName: 'Total',
      valueGetter: totalValueFormatter,
      flex: 1,
      minWidth: 100,
      valueFormatter: (params: any) => {
        if (currencyFields.includes(params.data.field)) {
          if (!params.value) return '-';
          return formattedCurrency(
            params.value,
            'en-US',
            'USD',
            true,
            twoDecimalRateFormatter
          );
        }
        return params.value;
      },
    },
    ...sortBy(data, 'stay_date').map((dataEntry) => ({
      headerName: formatDate(dataEntry.stay_date),
      field: dataEntry.stay_date,
      flex: 1,
      minWidth: 100,
      valueFormatter: (params: any) => {
        if (currencyFields.includes(params.data.field)) {
          if (!params.value) return '-';
          return formattedCurrency(
            params.value,
            'en-US',
            'USD',
            true,
            twoDecimalRateFormatter
          );
        }
        return params.value;
      },
    })),
  ];

  return (
    <div>
      {data.length ? (
        <>
          <div className='tw-flex tw-items-center tw-mb-3 tw-h-[100px]'>
            <div className='tw-flex tw-justify-center tw-items-center tw-text-lg tw-font-bold tw-shadow-md tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-mx-2 tw-px-6 tw-h-full'>
              Take Group?{' '}
              {take_group === undefined ? null : take_group ? (
                <FaCheck className='tw-text-green tw-ml-2 tw-text-xl' />
              ) : (
                <MdClose className='tw-text-red-500 tw-ml-2 tw-text-xl' />
              )}
            </div>
            {currentWinProbability ? (
              <div className='tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-mx-2 tw-py-3 tw-shadow-md tw-h-full'>
                <SuggestedRate
                  tier='Current'
                  suggestedRate={formValues.rate_requested}
                  winProbability={currentWinProbability}
                />
              </div>
            ) : null}
            <div className='tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-mx-2 tw-py-3 tw-flex tw-shadow-md tw-divide-x-2 tw-divide-gray-300 tw-h-full'>
              {optimalGroupRate && optimalWinProbability ? (
                <SuggestedRate
                  suggestedRate={optimalGroupRate}
                  winProbability={optimalWinProbability}
                  tier='Optimal'
                  setValue={setValue}
                />
              ) : null}
              {floorGroupRate && floorWinProbability ? (
                <SuggestedRate
                  suggestedRate={floorGroupRate}
                  winProbability={floorWinProbability}
                  tier='Floor'
                  setValue={setValue}
                />
              ) : null}
              {ceilingGroupRate && ceilingWinProbability ? (
                <SuggestedRate
                  suggestedRate={ceilingGroupRate}
                  winProbability={ceilingWinProbability}
                  tier='Ceiling'
                  setValue={setValue}
                />
              ) : null}
            </div>
          </div>
          <div className='group-price-tool__grid'>
            <div className='ag-theme-alpine'>
              <AgGridReact
                ref={gridRef}
                rowData={transformedData}
                columnDefs={columnDefs}
                domLayout='autoHeight'
                defaultColDef={defaultColOptions}
                rowHeight={35}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};
