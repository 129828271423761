import { MenuItem, Switch, styled } from '@mui/material';
import { Field, useFormikContext } from 'formik';

import styles from '@/common/_variables.module.scss';
import { RatesFormValues } from '@/features/rules/rates/zod';
import { ProxyType } from '@/graphql/gql-types';
import { useCompetitorsController } from '@/pages/rules/components/competitors/use-competitors-controller';
import { RateProxyOffsetInput } from 'components/rate-proxy-offset-input/rate-proxy-offset-input';
import { selectCurrentSeason } from 'features/rules/redux/rules-slice';
import { Select } from 'formik-mui';
import { useAppSelector } from 'redux/hooks';

const proxyTypeOptions = [
  { label: '+', value: ProxyType.value },
  { label: '%', value: ProxyType.percent },
];

const GreenSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    color: styles.colorGray,
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: styles.colorGray,
  },
  '.Mui-checked': {
    color: styles.colorGreen,
  },
  '& .Mui-checked + .MuiSwitch-track': {
    backgroundColor: styles.colorGreen,
  },
}));

type RateProxyFormProps = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  isReadOnly: boolean;
  values: RatesFormValues;
};

export const RateProxyForm = ({
  handleChange,
  isReadOnly,
  values,
}: RateProxyFormProps) => {
  const { setFieldValue } = useFormikContext();
  const { propertyCompetitors } = useCompetitorsController();
  const selectedSeason = useAppSelector(selectCurrentSeason);

  const proxyCompIdOptions = propertyCompetitors?.map((comp) => ({
    label: comp.comp_name,
    value: comp.comp_id,
  }));

  const handleProxyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedSeason) return;
    const show = event.target.checked;
    setFieldValue('use_proxy', show);
  };

  return (
    <>
      <div className='row'>
        <h2 className='header'>
          <GreenSwitch
            checked={values.use_proxy}
            onChange={handleProxyChange}
            size='small'
          />
          Rate Proxy
        </h2>
      </div>
      {values.use_proxy && (
        <>
          <div className='body'>
            <div className='field-box'>
              <Field
                className='common-dropdown-container rate-proxy'
                name='proxy_comp_id'
                component={Select}
                disabled={isReadOnly}
              >
                <MenuItem key='market' value={0}>
                  Market Rate
                </MenuItem>
                {proxyCompIdOptions?.map(({ label, value }) => (
                  <MenuItem key={label} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
            </div>
            <div className='field-box'>
              <div className='label'>Offset</div>
              <Field
                className='common-dropdown-container offset'
                name='proxy_type'
                component={Select}
                disabled={isReadOnly}
              >
                {proxyTypeOptions.map(({ label, value }) => (
                  <MenuItem key={label} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
              <RateProxyOffsetInput
                name='proxy_offset_amt'
                defaultValue={values.proxy_offset_amt}
                onChange={handleChange}
                proxyType={values.proxy_type}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
