import { Field } from 'formik';
import { MenuItem } from '@mui/material';
import { NumberInput } from '@/components/number-input/number-input';
import { OccupancyType } from 'graphql/gql-types';
import { RatesFormValues } from '@/features/rules/rates/zod';
import { Select } from 'formik-mui';

const occupancyTypeOptions = [
  { label: 'Single', value: OccupancyType.single },
  { label: 'Double', value: OccupancyType.double },
];

const signOptions = ['+', '-'];

type DerivedRateFormProps = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  isReadOnly: boolean;
  values: RatesFormValues;
};

export const DerivedRateForm = ({
  handleChange,
  isReadOnly,
  values,
}: DerivedRateFormProps) => {
  return (
    <>
      <div className='row'>
        <h2 className='header'>Derived Rate</h2>
      </div>
      <div className='body'>
        <div className='field-box'>
          <div className='label'>Occupancy</div>
          <Field
            className='common-dropdown-container'
            name='derivedRateType'
            component={Select}
            disabled={isReadOnly}
          >
            {occupancyTypeOptions.map(({ label, value }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </Field>
        </div>
        <div className='field-box'>
          <div className='label'>Offset</div>
          <Field
            className='common-dropdown-container offset'
            name='derivedRateOffsetSign'
            component={Select}
            disabled={isReadOnly}
          >
            {signOptions.map((sign) => (
              <MenuItem key={sign} value={sign}>
                {sign}
              </MenuItem>
            ))}
          </Field>
          <NumberInput
            name='derivedRateOffset'
            value={values.derivedRateOffset}
            onChange={handleChange}
            isFloat
            disabled={isReadOnly}
            maxAmount={
              values.maxRate ? parseFloat(values.maxRate) * 0.2 : undefined
            }
          />
        </div>
      </div>
    </>
  );
};
