import './dashboard.scss';

import { DEFAULT_SEARCH_ID, Search } from '../../types/SearchTypes';
import {
  DeleteSearchMutation,
  SaveSearchMutation,
} from 'features/my-view/gql/_gen_/rec-review.gql';
import { MdEdit, MdManageSearch } from 'react-icons/md';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  SearchInfo,
  SearchMetric,
  SearchMetricInfo,
  UserSearch,
} from 'graphql/gql-types';
import {
  selectDefaultSearchId,
  selectEnableSellOutFlag,
  selectShowAskRev,
} from 'features/header/redux/selectors';
import {
  selectRecPriceChangeSearchId,
  selectSearchId,
  selectShowSearchPanel,
} from 'features/my-view/redux/selectors';
import {
  setQueryInput,
  setSearchId,
  setShowSearchPanel,
} from 'features/my-view/redux/my-view-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  useSaveSearch,
  useSearchMetrics,
  useUserSearches,
} from '../../hooks/useUserSearches';

import { AskRev } from './AskRev/AskRev';
import { DOW_ALL } from '../../features/search/search-helpers';
import { DashboardContent } from './DashboardContent';
import { DropdownMenu } from 'components/DropdownMenu/DropdownMenu';
import { FormikProps } from 'formik';
import IconButton from '@mui/material/IconButton';
import { Modal } from 'components/Modal/Modal';
import { REC_STATUS_ALL } from '../../features/rec-rates/rec-rate-helpers';
import { RecReviewSearch } from './RecReviewSearch/RecReviewSearch';
import RouterPromptModal from 'components/RouterPromptModal/RouterPromptModal';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import { labels } from 'locales/en.label';
import { menuType } from 'components/DropdownMenu/DropdownMenu';
import { omit } from 'lodash';
import styles from 'common/_variables.module.scss';
import { toFixedNumber } from 'helpers/math-helpers';
import { today } from '../../features/dates/date-helpers';
import { useDeleteSearch } from 'hooks/useDeleteSearch';
import { useUser } from 'features/users/context/userContext';

type InfoInput = {
  message: string;
  messageType: AlertColor;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const DashboardWrapper = () => {
  const dispatch = useAppDispatch();
  const { user } = useUser();
  const defaultSearchId = useAppSelector(selectDefaultSearchId);
  const showSearchPanel = useAppSelector(selectShowSearchPanel);
  const enableSellOutFlag = useAppSelector(selectEnableSellOutFlag);
  const showAskRev = useAppSelector(selectShowAskRev);
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);
  const [saveSearchName, setSaveSearchName] = useState('');
  const searchId = useAppSelector(selectSearchId);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [info, setInfo] = useState<InfoInput>({
    message: '',
    messageType: 'error',
  });
  const [askRevId, setAskRevId] = useState('');
  const { deleteSearch, loading: deleteLoading } = useDeleteSearch();

  const EMPTY_SEARCH: Search = {
    duration: 30,
    isEditable: true,
    selectedDays: DOW_ALL,
    searchId: undefined,
    searchName: '',
    searchType: '',
    startDate: today(),
    userId: user?.id,
    statuses: REC_STATUS_ALL,
    searchCriteria: [
      { lhsCode: '', operatorCode: '', rhsCode: '', rhsCustomValue: 0 },
    ] as SearchMetricInfo[],
  };

  const searchformRef = useRef<FormikProps<UserSearch>>(null);

  const { userSearches, loadingUserSearches } = useUserSearches(user?.id);
  const recPriceChangeSearchId = useAppSelector(selectRecPriceChangeSearchId);
  const [showSearch, setShowSearch] = useState(false);
  const { searchMetrics } = useSearchMetrics();
  const [searchDetails, setSearchDetails] = useState(EMPTY_SEARCH);
  const [currentSearch, setCurrentSearch] = useState(EMPTY_SEARCH);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const newSearch: Search = {
    duration: 30,
    isEditable: true,
    selectedDays: DOW_ALL,
    searchName: '',
    searchType: 'new',
    startDate: today(),
    userId: user?.id,
    statuses: REC_STATUS_ALL,
    searchCriteria: [],
  };

  useEffect(() => {
    if (!isFormChanged && searchDetails.searchType !== 'new') {
      dispatch(
        setQueryInput({
          searchId,
          userId: user?.id,
          startDate: searchDetails.startDate,
          duration: searchDetails.duration,
          statuses: searchDetails.statuses,
          selectedDays: searchDetails.selectedDays,
          searchName: searchDetails.searchName,
          searchCriteria: searchDetails.searchCriteria as SearchMetricInfo[],
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId, user?.id, searchDetails]);

  useEffect(() => {
    const defaultInput: SearchInfo = {
      userId: user?.id,
      duration: 365,
      selectedDays: DOW_ALL,
      startDate: today(),
      statuses: REC_STATUS_ALL,
    };

    if (showAskRev || enableSellOutFlag) {
      setShowSearch(true);
      dispatch(setQueryInput(defaultInput));
    } else {
      setShowSearch(false);
      dispatch(setQueryInput(currentSearch));
      dispatch(setShowSearchPanel(false));
    }
  }, [currentSearch, dispatch, enableSellOutFlag, showAskRev, user?.id]);

  const [isBlocked, setIsBlocked] = useState(false);
  const onConfirmtionWindowClose = useCallback(
    () => setIsConfirmationModalOpen(false),
    []
  );
  const onSearchSaveSuccess = useCallback(
    (data: SaveSearchMutation) => {
      if (data.saveSearch?.searchId) {
        setInfo({
          message: labels.rec_review.search.save_search_msg,
          messageType: 'success',
        });
      }
      setShowConfirmMessage(true);
      showSearchPanel && dispatch(setShowSearchPanel(false));
    },

    [dispatch, showSearchPanel]
  );
  const { saveSearch, saveSearchLoading } = useSaveSearch(onSearchSaveSuccess);

  const hideConfirmMessage = () => {
    setShowConfirmMessage(false);
  };

  const onSearch = () => {
    if (searchformRef.current) {
      const values = {
        duration: searchformRef.current.values.duration,
        searchName: searchformRef.current.values.searchName,
        selectedDays: searchformRef.current.values.selectedDays,
        startDate: searchformRef.current.values.startDate,
        userId: searchformRef.current.values.userId,
        statuses: searchformRef.current.values.statuses,
        searchCriteria: convertRhsCustomValueToNumber(
          searchformRef.current.values.searchCriteria as SearchMetricInfo[]
        ),
      };
      setIsFormChanged(searchformRef.current.dirty);
      dispatch(setQueryInput({ ...values }));
    }
  };

  const convertRhsCustomValueToNumber = (data?: SearchMetricInfo[]) => {
    if (!data) return;

    const fractionDigits: Record<string, number> = {
      available_rooms: 0,
      rec_rate: 2,
      market_rate: 2,
      current_rate: 2,
      current_otb: 0,
      total_fcst: 0,
      adr: 2,
      occ_pct: 1,
      fcst_occ_pct: 1,
    };

    return data.map(({ rhsCustomValue, ...rest }) => {
      const { lhsCode } = rest;
      if (typeof rhsCustomValue === 'string')
        rhsCustomValue = parseFloat(rhsCustomValue);
      if (fractionDigits.hasOwnProperty(lhsCode)) {
        rhsCustomValue = toFixedNumber(rhsCustomValue, fractionDigits[lhsCode]);
      }
      return {
        ...rest,
        rhsCustomValue,
      };
    });
  };

  const onSearchSaveAs = () => {
    if (searchformRef.current) {
      const values = {
        duration: searchformRef.current.values.duration,
        searchName: searchformRef.current.values.searchName,
        selectedDays: searchformRef.current.values.selectedDays,
        startDate: searchformRef.current.values.startDate,
        userId: user?.id,
        statuses: searchformRef.current.values.statuses,
        searchCriteria: convertRhsCustomValueToNumber(
          searchformRef.current.values.searchCriteria as SearchMetricInfo[]
        ),
      };
      saveSearch({ variables: { search: values } });
      values.searchName && setSaveSearchName(values.searchName);
    }
  };

  const onDelete = () => {
    if (searchformRef.current) {
      deleteSearch({
        variables: { searchId: searchformRef.current.values.searchId },
        onCompleted: (data: DeleteSearchMutation) => {
          if (data.deleteSearch?.success) {
            setInfo({
              message: labels.rec_review.search.delete_search_msg_success,
              messageType: 'success',
            });

            dispatch(setSearchId(defaultSearchId || DEFAULT_SEARCH_ID));
            setShowConfirmMessage(true);
          } else {
            setInfo({
              message: labels.rec_review.search.delete_search_msg_error,
              messageType: 'error',
            });
          }
        },
      });
    }
  };

  const onSearchSave = () => {
    if (searchformRef.current) {
      const values = {
        duration: searchformRef.current.values.duration,
        searchId: searchformRef.current.values.searchId,
        searchName: searchformRef.current.values.searchName,
        selectedDays: searchformRef.current.values.selectedDays,
        startDate: searchformRef.current.values.startDate,
        userId: searchformRef.current.values.userId,
        statuses: searchformRef.current.values.statuses,
        searchCriteria: convertRhsCustomValueToNumber(
          searchformRef.current.values.searchCriteria as SearchMetricInfo[]
        )?.map((x) => omit(x, ['__typename', 'searchId'])),
      };
      saveSearch({ variables: { search: values } });
      values.searchName && setSaveSearchName(values.searchName);
    }
  };

  const onAskRevSelection = (e: { currentTarget: { value: string } }) => {
    const askRevselectionVal = e.currentTarget.value;
    if (askRevselectionVal) {
      setAskRevId(askRevselectionVal);
    } else {
      setAskRevId('');
    }
  };

  // TODO: Need to check if this is required
  useEffect(() => {
    if (userSearches && searchId && searchId > 0) {
      const search_details = userSearches.find(
        (item) => item?.searchId === searchId
      ) as Search;
      if (search_details) {
        setSearchDetails(search_details);
        setCurrentSearch(search_details);
      }
    }
    if (saveSearchName !== '') {
      const result = userSearches?.filter((obj) => {
        return obj?.searchName === saveSearchName;
      });
      const id = result ? result[0]?.searchId : 0;
      id && dispatch(setSearchId(id));
    }
  }, [dispatch, saveSearchName, searchId, userSearches]);

  const onSearchClick = () => {
    if (searchformRef?.current?.dirty) {
      setIsConfirmationModalOpen(true);
    } else {
      onClose();
    }
  };

  const onClose = () => {
    dispatch(setShowSearchPanel(!showSearchPanel));
  };

  const onSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const newSearchId = Number(e.target.value);
    if (newSearchId === recPriceChangeSearchId) {
      dispatch(
        setQueryInput({
          searchId: newSearchId,
          searchCriteria: [
            {
              lhsCode: 'rec_rate',
              operatorCode: '!=',
              rhsCode: 'current_rate',
              rhsCustomValue: 0,
            },
          ],
        })
      );
    }
    dispatch(setSearchId(newSearchId));
    setIsFormChanged(false);
    setSaveSearchName('');
  };

  const onCreateSearch = () => {
    searchformRef.current?.resetForm({ values: newSearch });
    setSearchDetails(newSearch);
  };

  const onReset = () => {
    setIsFormChanged(false);
    searchformRef.current?.resetForm({ values: currentSearch });
    setSearchDetails(currentSearch);
    dispatch(setQueryInput(currentSearch));
  };

  let searchListOptions: menuType[] = [];

  if (userSearches) {
    const customSearches = userSearches
      .filter((search) => search?.isEditable)
      .sort((a, b) => {
        return (a?.searchId ?? 0) - (b?.searchId ?? 0);
      });

    const defaultSearches = userSearches
      .filter((search: UserSearch | undefined) => search && !search.isEditable)
      .sort((a, b) => {
        return (a?.searchId ?? 0) - (b?.searchId ?? 0);
      });

    const defaultIndex = defaultSearches.findIndex(
      (search) => search?.searchId === defaultSearchId
    );

    const defaultSearch = defaultSearches.splice(defaultIndex, 1)[0];

    const sortedSearches = [
      defaultSearch,
      ...defaultSearches,
      ...customSearches,
    ];

    searchListOptions = sortedSearches.map((option) => {
      return {
        value: option?.searchId?.toString() || '',
        label: option?.searchName || '',
        icon: option?.isEditable ? (
          <MdEdit
            size={15}
            color={styles.colorPrimary}
            onClick={onSearchClick}
          />
        ) : (
          ''
        ),
      };
    });
  }

  return (
    <div className='dashboard'>
      <RouterPromptModal isBlocked={isBlocked} />
      <Modal
        headerText={labels.rec_review.search.confirmation}
        isShown={isConfirmationModalOpen}
        hide={() => setIsConfirmationModalOpen(false)}
        onConfirm={() => {
          onClose();
          setIsConfirmationModalOpen(false);
          setIsBlocked(false);
        }}
        onCancel={onConfirmtionWindowClose}
        isConfirmationModal={true}
        confirmBtnText={labels.rec_review.search.yes}
        cancelBtnText={labels.rec_review.search.no}
        messageBody={labels.rec_review.search.discard_search_msg}
        hasIcon={true}
      />
      <Snackbar
        className='error-masg'
        open={showConfirmMessage}
        autoHideDuration={3000}
        onClose={hideConfirmMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Alert
          onClose={hideConfirmMessage}
          severity={info.messageType}
          sx={{ width: '100%' }}
        >
          {info.message}
        </Alert>
      </Snackbar>
      {showAskRev && <AskRev handleChange={onAskRevSelection} />}
      {!showSearch && (
        <div className='search'>
          <div className='search__search-list'>
            <DropdownMenu
              options={searchListOptions}
              preSelectValue={searchId || defaultSearchId!}
              handleChange={onSearchChange}
              className='dashboard__search'
              loading={loadingUserSearches}
            />
            <Tooltip
              title={labels.rec_review.search.tooltip.manage_search}
              arrow
              placement='top'
            >
              <span>
                <IconButton
                  disableFocusRipple
                  disableRipple
                  aria-label='remove-search-criteria'
                  onClick={onSearchClick}
                  disabled={loadingUserSearches}
                >
                  <MdManageSearch
                    className='search__icon'
                    size={37}
                    color={'#905959'}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </div>
          <div className='search__panel'>
            {showSearchPanel && (
              <RecReviewSearch
                searchList={userSearches as UserSearch[]}
                id='recReivewSearch'
                formRef={searchformRef}
                onSearch={onSearch}
                onSaveAs={onSearchSaveAs}
                onDelete={onDelete}
                onSave={onSearchSave}
                onCreate={onCreateSearch}
                onReset={onReset}
                searchMetrics={searchMetrics as SearchMetric[]}
                searchData={searchDetails}
                onClose={onClose}
                setIsBlocked={setIsBlocked}
                loading={saveSearchLoading || deleteLoading}
              />
            )}
          </div>
        </div>
      )}

      <div className='dashboard__wrapper'>
        <DashboardContent
          askRevId={askRevId}
          startDate={searchDetails.startDate}
          paginationMaxDuration={searchDetails.duration || 30}
        />
      </div>
    </div>
  );
};
