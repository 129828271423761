import { GroupPriceToolInput } from './group-price-tool';
import type { UseFormSetValue } from 'react-hook-form';
import { usePropertyContext } from '@/context/propertyContext';

interface SuggestedRateProps {
  suggestedRate: number;
  winProbability: number;
  tier: string;
  setValue?: UseFormSetValue<GroupPriceToolInput>;
}

export const SuggestedRate = ({
  suggestedRate,
  winProbability,
  tier,
  setValue,
}: SuggestedRateProps) => {
  const { currencySymbol } = usePropertyContext();
  return (
    <div className='tw-flex tw-flex-col tw-px-4'>
      <p className='tw-text-sm tw-text-muted-foreground tw-mb-1'>{tier}</p>
      <p
        onClick={
          setValue
            ? () => setValue('rate_requested', Number(suggestedRate.toFixed(2)))
            : undefined
        }
        className='tw-text-2xl tw-font-bold tw-cursor-pointer hover:tw-text-gray-700'
      >
        {currencySymbol}
        {suggestedRate.toFixed(2)}
      </p>
      <p className='tw-text-sm tw-text-gray-500'>
        Win Probability: {(winProbability * 100).toFixed(2)}%
      </p>
    </div>
  );
};
