import { MenuItem } from '@mui/material';
import { Field } from 'formik';
import { Select } from 'formik-mui';
import { PricingStrategy } from 'graphql/gql-types';

const pricingStrategyOptions = [
  { label: 'Neutral', value: PricingStrategy.neutral },
  { label: 'Aggressive', value: PricingStrategy.aggressive },
  { label: 'Conservative', value: PricingStrategy.conservative },
];

type PricingStrategyFormProps = {
  hide: boolean;
};

export const PricingStrategyForm = ({ hide }: PricingStrategyFormProps) => {
  if (hide) {
    return null;
  }

  return (
    <div className='row'>
      <h2 className='header'>Pricing Strategy</h2>
      <div className='common-dropdown-container strategy'>
        <Field name='pricingStrategy' component={Select}>
          {pricingStrategyOptions.map(({ label, value }) => (
            <MenuItem key={label} value={value}>
              {label}
            </MenuItem>
          ))}
        </Field>
      </div>
    </div>
  );
};
