import { Autopilot } from './components/autopilot/autopilot';
import { Competitors } from './components/competitors/competitors';
import { Rates } from '../../features/rules/rates';
import { Seasons } from './components/seasons/seasons';
import { Tabs } from './components/rule-tabs/rule-tabs';
import { useDefaultSeason } from 'features/rules/rules-hooks';
import { useParams } from 'react-router-dom';

const components = {
  seasons: Seasons,
  competitors: Competitors,
  rates: Rates,
  autopilot: Autopilot,
};

export const Rules = () => {
  const { selectedTab } = useParams<{ selectedTab: Tabs }>();
  useDefaultSeason();

  const Component = components[selectedTab] || Seasons;

  return (
    <div className='rules' style={{ width: '97%' }}>
      <Component />
    </div>
  );
};
