import { OutlinedInput, OutlinedInputProps } from '@mui/material';

import { InputAdornment } from '@mui/material';
import { usePropertyContext } from 'context/propertyContext';
import { ProxyType } from 'graphql/gql-types';
import React from 'react';

interface RateProxyOffsetInputProps extends OutlinedInputProps {
  proxyType: ProxyType;
}

export const RateProxyOffsetInput: React.FC<RateProxyOffsetInputProps> = ({
  value,
  onChange,
  proxyType,
  ...others
}) => {
  const { currencySymbol } = usePropertyContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const isValid =
      proxyType === ProxyType.value
        ? new RegExp(/^-?\d{0,15}(\.\d{0,2})?$/)
        : new RegExp(/^-?\d{0,15}(\.\d{0,1})?$/);

    if (!isValid.test(value)) {
      return;
    }

    onChange?.(event);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  return (
    <div className='common-dropdown-container'>
      <OutlinedInput
        className='number-input'
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        startAdornment={
          proxyType === ProxyType.value && (
            <InputAdornment position='start'>{currencySymbol}</InputAdornment>
          )
        }
        {...others}
        placeholder='0.00'
      />
    </div>
  );
};
