import { CompRateObj, RecReviewData } from './useDashboardController';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import { Tooltip, capitalize } from '@mui/material';

import { CompRateTooltip } from './components/comp-rate-tooltip/comp-rate-tooltip';
import { FaRegCalendarCheck } from 'react-icons/fa';
import { HtmlTooltip } from 'components/html-tooltip';
import { ICellRendererParams } from 'ag-grid-community';
import { Link } from 'react-router-dom';
import { LiveDataField } from 'components/live-data-field';
import { LiveMarketTooltip } from './components/live-market-tooltip/live-market-tooltip';
import { Maybe } from 'types/maybe-type';
import _ from 'lodash';
import dayjs from 'dayjs';
import { labels } from 'locales/en.label';
import { priorityColors } from 'pages/home/helpers';

enum recStatus {
  P = 'Pending',
  S = 'Sent',
  R = 'Review',
  N = 'No Change',
  E = 'Error',
}

export const calcTotalForecast = (data?: Partial<RecReviewData>) => {
  if (data) {
    const {
      group_booked = 0,
      transient_fcst = 0,
      total_fcst = 0,
      total_capacity = 0,
    } = data;
    const { group_booked_live = group_booked } = data;

    const totalForecastOccInclGroup =
      total_capacity < 1 ? 0 : (total_fcst / total_capacity) * 100;
    const totalForecastOccInclGroupLive =
      total_capacity < 1
        ? 0
        : ((transient_fcst + group_booked_live) / total_capacity) * 100;
    return {
      fcst_occ_pct: totalForecastOccInclGroup,
      fcst_occ_pct_live: totalForecastOccInclGroupLive,
      total_fcst_incl_group: total_fcst,
      total_fcst_incl_group_live: transient_fcst + group_booked_live,
    };
  }
};

export const renderPriority = (value: Maybe<number>) => {
  if (_.isNil(value)) return null;
  return (
    <Tooltip
      title={`${capitalize(labels.common.rec_levels[value])} ${
        labels.common.priority
      }`}
      arrow
      placement='top'
    >
      <span
        style={{
          display: 'inline-block',
          background: priorityColors[value],
          width: '15px',
          height: '15px',
          borderRadius: '12px',
        }}
      />
    </Tooltip>
  );
};

export const renderEvent = (params: any) => {
  if (Object.keys(params.value).length > 0) {
    return (
      <span
        style={{
          display: 'inline-block',
          verticalAlign: 'sub',
          cursor: 'pointer',
        }}
      >
        <Link to='/events'>
          {' '}
          <FaRegCalendarCheck color='#FF9142' size={20} />
        </Link>
      </span>
    );
  }
  return '';
};

export const renderRecStatusCellStyle = (params: any) => {
  if (params.value) {
    const val = params.value;
    return <span>{Object(recStatus)[val]}</span>;
  }
  return '';
};

const renderMessage = (message?: string) => {
  const { messages } = labels.comp_rates;
  switch (message) {
    // No rates shopped
    case 'general.missing':
      return messages.missing;
    // No availability
    case 'rates.soldout':
      return messages.soldout;
    // No Best Flex Rate Found
    case 'rates.nobar':
      return messages.nobar;
    // No rates for 1 person found
    case 'rates.no_person.1':
      return messages.no1p;
    // No rates for 2 people found
    case 'rates.no_person.2':
      return messages.no2p;
    // Mealtype breakfast is missing
    case 'rates.meal.missing.1':
      return messages.noBkfst;
    // Roomtype is missing
    case 'rates.roomtypes.unavailable':
      return messages.noRmType;
    // Only availability for LOS2 or above
    case 'rates.restrictionlos2':
      return messages.los2;
    // Only availability for LOS3 or above
    case 'rates.restrictionlos3':
      return messages.los3;
    default:
      return message;
  }
};

const PreviousRateChange = ({ value }: { value: number }) => (
  <span className={`prev-rate-change ${value > 0 ? 'inc' : 'dec'}`}>
    {value > 0 ? <FaArrowUp /> : <FaArrowDown />}
    <sup>{Math.abs(value)}</sup>
  </span>
);

export const renderCompRateCell = (
  compRate: CompRateObj,
  bwRate: number,
  locale: string,
  currencyCode: string
) => {
  const { value, message, extractDateTime } = compRate || {};
  if (value === undefined || value === null) return null;

  // extractDateTime defaults to 1970-01-01T00:00:00 when value is 0
  const daysFromUpdate =
    value === 0 ? 0 : dayjs().diff(extractDateTime, 'days');

  let bwRateDiff = '';
  if (value > bwRate) bwRateDiff = 'inc';
  else if (value < bwRate) bwRateDiff = 'dec';

  const previousValue = compRate.changes?.[0]?.value || value;
  const prevRateDiff = Math.round(value - previousValue);

  return (
    <div className='comp-rate-cell'>
      {prevRateDiff !== 0 && <PreviousRateChange value={prevRateDiff} />}
      <CompRateTooltip data={compRate}>
        <div>
          {value === 0 ? (
            renderMessage(message)
          ) : (
            <span className={bwRateDiff}>
              {formattedCurrency(
                value.toString(),
                locale,
                currencyCode,
                true,
                twoDecimalRateFormatter
              )}
            </span>
          )}
        </div>
      </CompRateTooltip>
      {daysFromUpdate > 0 && (
        <Tooltip
          title={`Last updated ${daysFromUpdate} day${
            daysFromUpdate > 1 ? 's' : ''
          } ago`}
          placement='right'
        >
          <sup className='days-from-update'>{daysFromUpdate}</sup>
        </Tooltip>
      )}
    </div>
  );
};

interface LiveMarketRenderProps {
  value: number;
  data: {
    compRates: {
      one_day: number;
      three_day: number;
      seven_day: number;
    };
    stay_date: string;
  };
  locale: string;
  currencyCode: string;
}

export const renderLiveMarketRate = ({
  value,
  data,
  locale,
  currencyCode,
}: LiveMarketRenderProps) => {
  const tooltipData = {
    marketRate: value,
    changes: [
      {
        day: 1,
        value: data.compRates?.one_day,
      },
      {
        day: 3,
        value: data.compRates?.three_day,
      },
      {
        day: 7,
        value: data.compRates?.seven_day,
      },
    ],
  };

  return (
    <div>
      {value ? (
        <LiveMarketTooltip data={tooltipData} stayDate={data.stay_date}>
          <span>
            {formattedCurrency(
              value.toString(),
              locale,
              currencyCode,
              true,
              twoDecimalRateFormatter
            )}
          </span>
        </LiveMarketTooltip>
      ) : (
        '-'
      )}
    </div>
  );
};

/** JIRA-1252
 * Prices, ADR, RevPAR, Rates should have 2 decimal
 * Occupancy - 1 decimal
 * Anything related to rooms (Sold, Rooms Available) - no decimals/integer
 * Revenue - 0 decimals and thousands separator
 **/
export const twoDecimalRateFormatter = (value: number | string) => {
  if (!value) return '0.00';
  if (typeof value === 'string') value = parseFloat(value);
  return value.toFixed(2);
};

export const oneDecimalFormatter = (value: any) => {
  if (value) {
    return parseFloat(value).toFixed(1) + ' %';
  }
  return value;
};

export const zeroDecimalFormatter = (value: any) => {
  if (value) {
    return parseFloat(value);
  }
  return value;
};

export const thousandSeperatorRateFormatter = (value: any) => {
  if (value !== undefined && value !== null) {
    const rateWithComma = parseFloat(value.toFixed())
      .toLocaleString()
      .replace(/\.([0-9])$/, '.$10');
    return rateWithComma;
  }
  return value;
};

const daysOfWeekMapper: any = {
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
  Sun: 7,
};

export const daysOfWeekSort = (day1: string, day2: string) => {
  return daysOfWeekMapper[day1] - daysOfWeekMapper[day2];
};

export const rateLevelFcstValueGetter = (
  params: any,
  fcstRateLvlType: string
) => {
  const renderRateLvlLiveData = (
    otb: string,
    otb_live: string,
    fcst: string,
    status: string
  ) => {
    const hasLiveData = otb_live && otb !== otb_live ? true : false;
    if (hasLiveData) {
      return (
        <HtmlTooltip placement='right' title={otb === null ? '-' : otb}>
          <div className='liveCell'>
            <span className='liveCell__value'>{otb_live}</span>
            <span className='liveCell__star'>*</span>
            <span className='liveCell__divider'>{'/'}</span>
            <span>{fcst ? fcst : '-'}</span>
            <span className='liveCell__divider'>{'/'}</span>
            <span>{status ? status : '-'}</span>
          </div>
        </HtmlTooltip>
      );
    } else {
      return (
        <div className='nonLiveCell'>
          <span className='nonLiveCell__value'>{otb ? otb : '-'}</span>
          <span className='nonLiveCell__divider'>{'/'}</span>
          <span>{fcst ? fcst : '-'}</span>
          <span className='nonLiveCell__divider'>{'/'}</span>
          <span>{status ? status : '-'}</span>
        </div>
      );
    }
  };
  if (params.data) {
    switch (fcstRateLvlType) {
      case 'a': {
        return renderRateLvlLiveData(
          params.data.a_current_otb,
          params.data.a_otb_live,
          params.data.a_fcst_sell,
          params.data.a_status
        );
      }
      case 'b': {
        return renderRateLvlLiveData(
          params.data.b_current_otb,
          params.data.b_otb_live,
          params.data.b_fcst_sell,
          params.data.b_status
        );
      }
      case 'c': {
        return renderRateLvlLiveData(
          params.data.c_current_otb,
          params.data.c_otb_live,
          params.data.c_fcst_sell,
          params.data.c_status
        );
      }
      case 'd': {
        return renderRateLvlLiveData(
          params.data.d_current_otb,
          params.data.d_otb_live,
          params.data.d_fcst_sell,
          params.data.d_status
        );
      }
      case 'e': {
        return renderRateLvlLiveData(
          params.data.e_current_otb,
          params.data.e_otb_live,
          params.data.e_fcst_sell,
          params.data.e_status
        );
      }
      case 'f': {
        return renderRateLvlLiveData(
          params.data.f_current_otb,
          params.data.f_otb_live,
          params.data.f_fcst_sell,
          params.data.f_status
        );
      }
      case 'g': {
        return renderRateLvlLiveData(
          params.data.g_current_otb,
          params.data.g_otb_live,
          params.data.g_fcst_sell,
          params.data.g_status
        );
      }
      case 'h': {
        return renderRateLvlLiveData(
          params.data.h_current_otb,
          params.data.h_otb_live,
          params.data.h_fcst_sell,
          params.data.h_status
        );
      }
      default:
        return '';
    }
  }
  return '';
};

/**
 * Show tooltip if there is live data and live_data !== old_data
 * @param params
 * @param liveColumn
 * @param nonLiveColumn
 * @param rateFormatter
 * @returns
 */
export const renderLiveData = (
  params: ICellRendererParams,
  liveColumn: string,
  nonLiveColumn: string,
  enableCurrencyFormatter: boolean,
  rateFormatter: (value: string) => string,
  removeDecimals?: boolean
) => {
  const oldVal = rateFormatter(params?.data?.[nonLiveColumn]);
  const liveVal = rateFormatter(params?.data?.[liveColumn]);

  return (
    <LiveDataField
      liveVal={liveVal}
      oldVal={oldVal}
      enableCurrencyFormatter={enableCurrencyFormatter}
      removeDecimals={removeDecimals}
    />
  );
};

export const formattedCurrency = (
  rate: string,
  locale: string,
  currencyCode: string,
  enableCurrencyFormatter: boolean,
  rateFormatter?: (value: number | string) => string,
  removeDecimals: boolean = false
) => {
  const data = rateFormatter
    ? parseFloat(rateFormatter(rate))
    : parseFloat(rate?.toString().replace(/,/g, ''));
  if (enableCurrencyFormatter && rate) {
    const formattedNumber = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: removeDecimals ? 0 : 2,
    }).format(data);
    return formattedNumber;
  } else {
    return rate;
  }
};
