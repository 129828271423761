/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHotelRateChangeLogsQueryVariables = Types.Exact<{
  propertyId: Types.Scalars['String'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetHotelRateChangeLogsQuery = { __typename?: 'Query', getHotelRateChangeLogs?: Array<{ __typename?: 'RateChangeLog', newRate?: number | undefined, previousRate?: number | undefined, stayDate?: string | undefined, updatedBy: string, createdAt: string } | undefined> | undefined };


export const GetHotelRateChangeLogsDocument = gql`
    query GetHotelRateChangeLogs($propertyId: String!, $limit: Int) {
  getHotelRateChangeLogs(propertyId: $propertyId, limit: $limit) {
    newRate
    previousRate
    stayDate
    updatedBy
    createdAt
  }
}
    `;

/**
 * __useGetHotelRateChangeLogsQuery__
 *
 * To run a query within a React component, call `useGetHotelRateChangeLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelRateChangeLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelRateChangeLogsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetHotelRateChangeLogsQuery(baseOptions: Apollo.QueryHookOptions<GetHotelRateChangeLogsQuery, GetHotelRateChangeLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHotelRateChangeLogsQuery, GetHotelRateChangeLogsQueryVariables>(GetHotelRateChangeLogsDocument, options);
      }
export function useGetHotelRateChangeLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHotelRateChangeLogsQuery, GetHotelRateChangeLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHotelRateChangeLogsQuery, GetHotelRateChangeLogsQueryVariables>(GetHotelRateChangeLogsDocument, options);
        }
export type GetHotelRateChangeLogsQueryHookResult = ReturnType<typeof useGetHotelRateChangeLogsQuery>;
export type GetHotelRateChangeLogsLazyQueryHookResult = ReturnType<typeof useGetHotelRateChangeLogsLazyQuery>;
export type GetHotelRateChangeLogsQueryResult = Apollo.QueryResult<GetHotelRateChangeLogsQuery, GetHotelRateChangeLogsQueryVariables>;
export const namedOperations = {
  Query: {
    GetHotelRateChangeLogs: 'GetHotelRateChangeLogs'
  }
}