import { NumberInput } from '@/components/number-input/number-input';
import { RatesFormValues } from '@/features/rules/rates/zod';

type RateBoundaryFormProps = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  isReadOnly: boolean;
  values: RatesFormValues;
};

export const RateBoundaryForm = ({
  handleChange,
  isReadOnly,
  values,
}: RateBoundaryFormProps) => {
  return (
    <>
      <div className='row'>
        <h2 className='header'>Rate Boundary</h2>
      </div>
      <div className='body'>
        <div className='field-box'>
          <div className='label'>Min</div>
          <NumberInput
            name='minRate'
            value={values.minRate}
            onChange={handleChange}
            isFloat
            disabled={isReadOnly}
          />
        </div>
        <div className='field-box'>
          <div className='label'>Max</div>
          <NumberInput
            name='maxRate'
            value={values.maxRate}
            onChange={handleChange}
            isFloat
            disabled={isReadOnly}
          />
        </div>
      </div>
    </>
  );
};
