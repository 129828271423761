import './EventsForm.scss';
import { useEffect, forwardRef, useImperativeHandle } from 'react';
import { MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  MdDeleteOutline,
  MdOutlineAddBox,
  MdOutlineIndeterminateCheckBox,
  MdOutlineSave,
} from 'react-icons/md';

import { NumberInput } from 'components/number-input/number-input';
import { labels } from 'locales/en.label';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useUser } from 'features/users/context/userContext';
import {
  EventCategoryEnum,
  EventFormData,
  EventRecordInput,
} from 'graphql/gql-types';
import {
  selectSelectedEvents,
  setIsEventsFormDirty,
  updateEventsFormValues,
} from 'features/events/redux/events-form-slice';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const eventTypeOptions = [
  { label: 'General', value: EventCategoryEnum.general },
  { label: 'Holiday', value: EventCategoryEnum.holiday },
  { label: 'Political', value: EventCategoryEnum.political },
  { label: 'Religious', value: EventCategoryEnum.religious },
  { label: 'School', value: EventCategoryEnum.school },
  { label: 'Sports', value: EventCategoryEnum.sports },
  { label: 'Festival', value: EventCategoryEnum.festival },
];

export interface EventsFormRef {
  values: EventFormData;
  dirty: boolean;
  resetForm: (params: { values: EventFormData }) => void;
}


const MIN_AMOUNT = -100;
const MAX_AMOUNT = 100;

const dateToday = new Date();
dateToday.setHours(0, 0, 0, 0);

const eventValidationSchema = z.object({
  name: z.string().nonempty(labels.events.validation.required),

  category: z.nativeEnum(EventCategoryEnum, {
    errorMap: () => ({ message: labels.events.validation.required }),
  }),

  records: z
    .array(
      z
        .object({
          id: z.string().optional(),
          event_id: z.string().optional().or(z.literal('')),
          start_date: z.coerce
            .date({ invalid_type_error: labels.events.validation.required })
            .min(dateToday, labels.events.validation.start_date_err),
          end_date: z.coerce.date({
            invalid_type_error: labels.events.validation.required,
          }),
        })
        .refine((data) => data.end_date >= data.start_date, {
          message: labels.events.validation.end_date_err,
          path: ['end_date'],
        })
    )
    .min(1, { message: labels.events.validation.required }),

  occ_impact: z
    .union([z.string(), z.number(), z.undefined()])
    .transform((val) => {
      if (val === undefined || val === '') {
        return undefined;
      }
      const num = Number(val);
      return isNaN(num) ? undefined : num;
    })
    .refine(
      (num) => num === undefined || (num >= MIN_AMOUNT && num <= MAX_AMOUNT),
      {
        message: `(Enter value between ${MIN_AMOUNT} to ${MAX_AMOUNT})`,
      }
    ),
});

export interface EventFormProps {
  id: string;
  formRef: React.Ref<EventsFormRef> | null;
  onFormReset: () => void;
  readonly: boolean;
  formData: EventFormData;
  onSaveClick: () => void;
  onDeleteClick: () => void;
  onRecordDelete: (record: EventRecordInput) => void;
}

const EventsForm = forwardRef<any, EventFormProps>((props, _unusedRef) => {
  const {
    formRef,
    formData,
    onSaveClick,
    onDeleteClick,
    onRecordDelete,
    onFormReset,
    readonly,
    id,
  } = props;

  const dispatch = useAppDispatch();
  const { isReadOnly } = useUser();
  const selectedEvents = useAppSelector(selectSelectedEvents);

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<EventFormData>({
    resolver: zodResolver(eventValidationSchema),
    defaultValues: formData,
    mode: 'onChange',
  });

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  const {
    fields: recordFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'records',
  });

  useEffect(() => {
    dispatch(setIsEventsFormDirty(isDirty));
  }, [isDirty, dispatch]);

  useImperativeHandle(formRef, () => ({
    get values() {
      return getValues();
    },
    resetForm: ({ values }: { values: EventFormData }) => {
      reset(values);
    },
    get dirty() {
      return isDirty;
    },
  }));

  const submitHandler = () => {
    onSaveClick();
  };

  const onCategoryChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setValue(name as keyof EventFormData, value);
    dispatch(updateEventsFormValues({ name: value }));
  };

  return (
    <div className='events'>
      <form
        className='events__form'
        id='events-form'
        onSubmit={handleSubmit(submitHandler)}
      >
        {/* Event Name */}
        <div className='events__form__row'>
          <div className='events__form__row-item events__form__row-item-event-name'>
            <TextField
              id='name'
              variant='outlined'
              placeholder='Event Name'
              disabled={isReadOnly || readonly}
              {...register('name')}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </div>
        </div>

        {/* Event Category */}
        <div className='events__form__row'>
          <div className='events__form__row-item events__form__row-item-event-type'>
            <Select
              defaultValue=''
              inputProps={{
                name: 'category',
                id: 'category',
              }}
              label='Event Category'
              disabled={isReadOnly || readonly}
              value={watch('category') || ''}
              onChange={onCategoryChange}
              error={!!errors.category}
            >
              {eventTypeOptions.map(({ label, value }) => (
                <MenuItem key={label} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            {errors.category && (
              <p className='error-text'>{errors.category.message}</p>
            )}
          </div>
        </div>

        {/* Records Array */}
        <div className='events__form__row events__form__row-event-records'>
          <div className='events__form__row-event-record'>
            {recordFields.map((record, index) => {
              return (
                <div
                  className='events__form__row events__form__row-event-record-date'
                  key={record.id || index}
                >
                  <div className='events__form__row-item'>
                    {/* Start Date */}
                    <Controller
                      control={control}
                      name={`records.${index}.start_date`}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label={labels.events.start_date}
                          value={field.value ?? null}
                          onChange={(dateVal) => field.onChange(dateVal)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin='normal'
                              error={
                                !!errors.records?.[index]?.start_date ||
                                !!errors.records?.[index]?.end_date
                              }
                              helperText={
                                errors.records?.[index]?.start_date?.message ||
                                errors.records?.[index]?.end_date?.message
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </div>

                  <div className='events__form__row-item'>
                    {/* End Date */}
                    <Controller
                      control={control}
                      name={`records.${index}.end_date`}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label={labels.events.end_date}
                          value={field.value ?? null}
                          onChange={(dateVal) => field.onChange(dateVal)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin='normal'
                              error={
                                !!errors.records?.[index]?.end_date ||
                                !!errors.records?.[index]?.start_date
                              }
                              helperText={
                                errors.records?.[index]?.end_date?.message ||
                                errors.records?.[index]?.start_date?.message
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </div>

                  {/* Remove Record Button */}
                  <div className='events__form__row-item'>
                    <Tooltip
                      title={labels.events.tooltip.remove_event_record}
                      arrow
                      placement='top'
                    >
                      <span>
                        <IconButton
                          disableFocusRipple
                          disableRipple
                          aria-label='remove-event-record'
                          onClick={() => {
                            if (recordFields.length > 1) {
                              remove(index);
                              onRecordDelete(record as EventRecordInput);
                            }
                          }}
                          className='events__form-remove-date-btn'
                          disabled={recordFields.length === 1}
                        >
                          <MdOutlineIndeterminateCheckBox size={22} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Add Record Button */}
          <div className='events__form__row-item-add'>
            <Tooltip
              title={labels.events.tooltip.add_event_record}
              arrow
              placement='top'
            >
              <span>
                <IconButton
                  disableFocusRipple
                  disableRipple
                  aria-label='add-event-record'
                  onClick={() =>
                    append({
                      id: '',
                      start_date: '',
                      end_date: '',
                      event_id: watch('event_id') || '',
                    })
                  }
                  className='events__form-add-date-btn'
                >
                  <MdOutlineAddBox size={22} />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </div>

        {/* Occupancy Impact */}
        <div className='events__form__row'>
          <div className='events__form__row-item events__form__row-item-label label'>
            {labels.events.occupancy_impact}
            <div className='events__form__row-item-label-sm'>
              {`(Enter value between ${MIN_AMOUNT} to ${MAX_AMOUNT})`}
            </div>
          </div>
          <div className='events__form__row-item'>
            <div className='magnitude'>
              <Controller
                control={control}
                name='occ_impact'
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    name='occ_impact'
                    disabled={isReadOnly || readonly}
                    showCurrencySymbol={false}
                    allowSigns={true}
                    maxAmount={MAX_AMOUNT}
                    minAmount={MIN_AMOUNT}
                    isFloat={false}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      field.onChange(value);
                      setValue(name as keyof EventFormData, value);
                    }}
                  />
                )}
              />
            </div>
            {errors.occ_impact && (
              <p className='error-text'>{errors.occ_impact.message}</p>
            )}
          </div>
          <div className='events__form__row-item label'>{'%'}</div>
        </div>

        {/* Footer (Save/Delete) */}
        <div className='events__form__row events__form-footer'>
          <button
            type='submit'
            disabled={!isDirty}
            className='events__form-save-btn'
          >
            <MdOutlineSave size={22} />
            {labels.common.save}
          </button>
          <button
            type='button'
            onClick={onDeleteClick}
            className='events__form-delete-btn'
            disabled={selectedEvents.length === 0}
          >
            <MdDeleteOutline size={22} /> {labels.common.delete}
          </button>
        </div>
      </form>
    </div>
  );
});

EventsForm.displayName = 'EventsForm';
export default EventsForm;
